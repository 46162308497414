import React, { useEffect } from "react";

function Test() {
  return (
    <>
      <nav className="navbar" id="section1">
        <span
          className="nav-toggle"
          id="js-nav-toggle"
          data-aos="flip-down"
          data-aos-delay="500"
        >
          <i className="fas fa-bars"></i>
        </span>
        <div className="logo">
          <div className="nav-title">
            <h1>FL2.Design</h1>
          </div>
        </div>
        <ul id="js-menu" data-aos="fade-left">
          <a href="#">
            <li>Galerie</li>
          </a>
          <a href="#section2">
            <li>À propos</li>
          </a>
          <a>
            <li>Contact</li>
          </a>
        </ul>
      </nav>
      <div className="content">
        <div className="main-header section" data-aos="zoom-in">
          <div className="main-header-content">
            <h1
              className="main-title"
              data-aos="fade-left"
              data-aos-delay="500"
            >
              &ensp;Retail.
              <br />
              Interior Design Studio.
              <br />
              &emsp;Architectural Designer.
              <br />
              Stores & Showrooms design.
            </h1>
            <img src="./images/main.jpg" alt="#" className="main-img" />
          </div>
        </div>
        <div
          className="arrow-section arrow-section-impair"
          data-aos="fade-left"
          data-aos-delay="500"
        >
          <a href="#section2">
            <i className="fa fa-arrow-down fa-3x" aria-hidden="true"></i>
          </a>
        </div>
        <div className="gap-section"></div>
        <div className="desc-section section" id="section2">
          <div className="desc-title" data-aos="fade-left" data-aos-delay="500">
            <h1>À propos</h1>
          </div>
          <div className="desc-text">
            <p className="desc">
              In sed lorem sed nibh congue consequat. Vivamus mollis, nunc in
              placerat semper, orci libero scelerisque massa, nec euismod lacus
              neque at dolor. In a justo vel magna venenatis fermentum a at
              eros. Aenean tristique finibus orci, ac tempor odio mattis in.
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
              volutpat vel urna ac faucibus. Quisque id erat vitae arcu
              vestibulum suscipit. Nam vehicula nunc dolor, ornare molestie
              massa interdum ac. Aenean eget vehicula enim.
            </p>
            <p className="desc">
              In sed lorem sed nibh congue consequat. Vivamus mollis, nunc in
              placerat semper, orci libero scelerisque massa, nec euismod lacus
              neque at dolor. In a justo vel magna venenatis fermentum a at
              eros. Aenean tristique finibus orci, ac tempor odio mattis in.
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
              volutpat vel urna ac faucibus. Quisque id erat vitae arcu
              vestibulum suscipit. Nam vehicula nunc dolor, ornare molestie
              massa interdum ac. Aenean eget vehicula enim.
            </p>
          </div>
          <div
            className="arrow-section arrow-section-pair"
            data-aos="fade-left"
            data-aos-delay="500"
          >
            <a href="#section1">
              <i className="fa fa-arrow-down fa-3x" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </div>
      <script src="app.js" type="text/javascript"></script>
      <script src="https://unpkg.com/aos@next/dist/aos.js"></script>
    </>
  );
}

export default Test;
