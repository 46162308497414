import React from "react";
// import Typewriter from "typewriter-effect";
import logo from "../../images/logo_fl2design.jpg";
import Lottie from "react-lottie";
import animationData from "../../svg/loading.json";
import { SocialIcon } from "react-social-icons";

function Home() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      <div className="construction">
        <img className="logo-construction" src={logo} alt="logo" />
        <div>
          <h2
            data-aos="fade-left"
            data-aos-delay="500"
            className="construction-title"
          >
            En construction / Under construction
          </h2>
        </div>
        <div>
          <Lottie
            className="svg-loading"
            options={defaultOptions}
            height={100}
            width={100}
            bgColor="white"
          />
        </div>
        <div className="contact-us">
          <h2 data-aos="fade-left" data-aos-delay="500">
            Nous contacter / Contact us
          </h2>
          <div data-aos="flip-down" className="social-icons">
            <SocialIcon
              style={{ height: 25, width: 25 }}
              className="social-icon"
              url="https://www.instagram.com/fl2design"
              bgColor="white"
            />
            <SocialIcon
              style={{ height: 25, width: 25 }}
              className="social-icon"
              url="https://ca.linkedin.com/in/fl2design"
              bgColor="white"
            />
          </div>
          <address>
            <a className="contact-email" href="mailto:Info@fl2design.com">
              Info@fl2design.com
            </a>
          </address>
        </div>
      </div>
    </>
  );
}

export default Home;
